import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/styles.scss";

//import App from "./pages/App";

import reportWebVitals from "./reportWebVitals";
import "./i18n";

import Router from "./routes/Routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router />
    {/*<App />*/}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
